/* eslint-disable */
import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import usertour from "usertour.js";
import "highlight.js/styles/atom-one-dark.css";
import "highlight.js/lib/common";
import hljsVuePlugin from "@highlightjs/vue-plugin";
import "@/assets/iconfont/iconfont.css";
import store from "./store";
// import draggable from 'vuedraggable'
import { initAhamomentUserView } from "@/tools/utils";

// 百度代码统计
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?0de5406d0dca52b0b49ea18ab9db26a9";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

// 初始化 ahamoment SDK
usertour.init("cm1w2ry9p00081gjw2rle46fm");
// 初始化 Ahamoment SDK
initAhamomentUserView({ username: "ssss" });

const app = createApp(App).use(router);
app.use(hljsVuePlugin);
app.use(ElementPlus);
app.use(store);
app.mount("#app");

// import Vue from 'vue'
import ElementUI from "element-plus";
import locale from "element-plus/es/locale/lang/zh-cn";

app.use(ElementUI, { locale });
