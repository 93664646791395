import { useRoute } from 'vue-router';
// eslint-disable-next-line
import { ref, reactive, toRefs, onMounted, watch } from "vue";
import GlobalExtensionVersion from "@/components/Extension/GlobalExtensionVersion.vue";
// eslint-disable-next-line
import { post, get, put } from "@/api/http";
import router from "@/router/index";
export default {
  name: "NavigationView",
  components: {
    GlobalExtensionVersion
  },
  // eslint-disable-next-line
  setup(props, {
    emit
  }) {
    const route = useRoute();
    onMounted(() => {
      console.log('app onMounted');
      getPermission();
      getIsOtherLogin();
    });
    watch(() => route.name, () => {
      getPermission();
      // getIsOtherLogin()
    });

    const getPermission = () => {
      const user = localStorage.getItem("currentBuserId");
      const tenant = localStorage.getItem("tenantId");
      if (user && tenant) {
        get(`/free/getPermission?userId=${user}&tenantId=${tenant}`).then(res => {
          if (res.data.success) {
            // console.log(res.data.data)
            localStorage.setItem("BBm_lvt_ccde5ad06d0dca1de0b49ea18ab9db26a9", res.data.data.role);
          }
        });
      }
    };
    const showOthersLogin = ref(false);
    const getIsOtherLogin = () => {
      // return
      // eslint-disable-next-line

      // const i = setInterval(() => {
      //   const user = localStorage.getItem("currentBuserId")
      //   const token = localStorage.getItem("token")
      //   if (user && token) {
      //     console.log(1)
      //     get(`/free/getUserIsLogin/${user}`).then((res) => {
      //       if (res.data.success) {
      //         const dbToken = res.data.data.token
      //         if (token !== dbToken) {
      //           showOthersLogin.value = true
      //           clearInterval(i)
      //         }
      //       }
      //     })
      //   }
      // }, 5000)
    };
    const reLogin = () => {
      showOthersLogin.value = false;
      router.push({
        path: "/login"
      });
    };
    return {
      showOthersLogin,
      reLogin
    };
  }
};