// 存放 storage 词典
// 插件数据 开始
export const STORAGE_CURRENT_CRX_INFO = 'currentCrxInfo' //当前用户的插件版本

export const STORAGE_CURRENT_CRX_VERSION = 'currentCrxVersion' //当前用户的本地插件版本

export const STORAGE_CURRENT_ONLINE_VERSION = 'currentOnLineVersion' //当前用户的线上插件版本

export const STORAGE_CURRENT_ONLINE_DETAIL = 'currentOnLineDetail' //当前用户的线上插件版本简介

export const STORAGE_CURRENT_ONLINE_UPDATE_TIME = 'currentOnLineUpdateTime' //当前用户的线上插件版本简介

export const STORAGE_CURRENT_CRX_FORCED_TYPE = 'currentCrxforcedType' //当前版本插件必须升级的 类型

export const CLIENT_DATA_STATE_KEY = 'aha:ahaClientDataState'

// 插件数据 结束


export const STORAGE_CURRENT_NO_ALERT_CRX_VERSION = 'currentNoAlertCrxVersion' //当前版本插件的提示 不更新的版本

export const STORAGE_CURRENT_VERSION_ALERT_DAY = 'currentCrxVersionAlertDay' //当前版本插件的提示  天级别


export const STORAGE_CURRENT_BUSER_ID = 'currentBuserId' //当前用户ID

export const STORAGE_CURRENT_CUSTOM_FIELD_SELECT = 'currenCustomFieldSelect' //当前操作已经选中的的自定义字段










