
const field = {
  showPageDataRules: {
    type:'default', 
    status: false, 
    message: '请至少添加一个特定展示页面', 
    trigger: 'blur' 
  }
}

const configRules = {
  namespaced: true,
  state: {
    showPageDataRules: JSON.parse(JSON.stringify(field.showPageDataRules))
  },
  mutations: {
    SET_RESET: (state) => {
      state.showPageDataRules = JSON.parse(JSON.stringify(field.showPageDataRules))
    },
    SET_SHOWPAGEDATARULES: (state, value) => {
      state.showPageDataRules.status = value
    },    
  },
  actions: {
      stateResett({ commit }, value) {
        commit('SET_RESET', value)
      },
      stateSetShowPageDataRules({ commit }, value) {
          commit('SET_SHOWPAGEDATARULES', value)
      },
  },
}

export default configRules
