import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/homepage/HomeView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/Login.vue"),
  },
  {
    path: "/invalidation",
    name: "invalidation",
    component: () => import("../views/login/Invalidation.vue"),
  },
  {
    path: "/bind",
    name: "bind",
    component: () => import("../views/login/LoginFirst.vue"),
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("@/views/preferens/Setting.vue"),
    children: [
      {
        path: "enterprise",
        name: "enterprise",
        component: () =>
          import("@/views/preferens/settingPage/EnterpriseInfo.vue"),
      },
      {
        path: "product",
        name: "productCenter",
        component: () =>
          import("@/views/preferens/settingPage/ProductCenter.vue"),
      },
      {
        path: "productDetail",
        name: "productDetail",
        component: () =>
          import("@/views/preferens/settingPage/ProductDetail.vue"),
      },
      {
        path: "member",
        name: "member",
        component: () =>
          import("@/views/preferens/settingPage/Member.vue"),
      },
      {
        path: "subscribe",
        name: "subscribe",
        component: () =>
          import("@/views/preferens/settingPage/Subscribe.vue"),
      },
    ],
  },
  { 
    path: "/editChatbox",
    name: "editchatbox",
    component: () => import("@/views/floating/EditChatbox.vue"),
    children: [
      {
        path: "text",
        name: "editchatboxtext",
        component: () => import("@/views/floating/FunctionText.vue"),
      },
      {
        path: "contact",
        name: "editchatboxcontact",
        component: () => import("@/views/floating/FunctionContact.vue"),
      },
      {
        path: "skip",
        name: "editchatboxjump",
        component: () => import("@/views/floating/FunctionSkip.vue"),
      },
    ]
  },
  {
    path: "/launcherLayout",
    name: "launcherLayout",
    component: () => import("@/views/launcher/LauncherDetailsLayout.vue"),
    children: [
      {
        path: "launcherEdit",
        name: "launcherEdit",
        component: () => import("@/views/launcher/LauncherEdit.vue"),
      },
      {
        path: "launcherData",
        name: "launcherData",
        component: () => import("@/views/launcher/LauncherData.vue"),
      },
    ]
  },
  {
    path: "/taskLayout",
    name: "taskLayout",
    component: () => import("@/views/task/TaskDetailsLayout.vue"),
    children: [
      {
        path: "taskEdit",
        name: "taskEdit",
        component: () => import("@/views/task/TaskEdit.vue"),
      },
      {
        path: "taskData",
        name: "taskData",
        component: () => import("@/views/task/TaskData.vue"),
      },
    ]
  },
  // {
  //   path: "/npsEdit",
  //   name: "npsEdit",
  //   component: () => import("@/views/nps/NpsEdit.vue"),
  // },
  {
    path: "/npsLayout",
    name: "npsLayout",
    component: () => import("@/views/nps/NpsDetailsLayout.vue"),
    children: [
      {
        path: "npsEdit",
        name: "npsEdit",
        component: () => import("@/views/nps/NpsEdit.vue"),
      },
      {
        path: "npsData",
        name: "npsData",
        component: () => import("@/views/nps/NpsData.vue"),
      },
    ]
  },
  {
    path: "/floatLayout",
    name: "floatLayout",
    component: () => import("@/views/float/FloatDetailsLayout.vue"),
    children: [
      {
        path: "floatEdit",
        name: "floatEdit",
        component: () => import("@/views/float/FloatEdit.vue"),
      },
      {
        path: "floatData",
        name: "floatData",
        component: () => import("@/views/float/FloatData.vue"),
      },
    ]
  },
  {
    path: "/noticeLayout",
    name: "noticeLayout",
    component: () => import("@/views/notice/NoticeDetailsLayout.vue"),
    children: [
      {
        path: "noticeEdit",
        name: "noticeEdit",
        component: () => import("@/views/notice/NoticeEdit.vue"),
      },
      {
        path: "noticeData",
        name: "noticeData",
        component: () => import("@/views/notice/NoticeData.vue"),
      },
    ]
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/nav/Navigation.vue"),
    children: [
      {
        path: "guild",
        name: "guild",
        component: () => import("@/views/guild/Guild.vue"),
      },
      {
        path: "float",
        name: "float",
        component: () => import("@/views/float/FloatList.vue"),

      },
      {
        path: "chatbox",
        name: "chatboxhome",
        component: () => import("@/views/floating/Nm.vue"),

      },
      {
        path: "launcher",
        name: "launcher",
        component: () => import("@/views/launcher/List.vue"),
      },
      {
        path: "nps",
        name: "nps",
        component: () => import("@/views/nps/NpsList.vue"),
      },
      {
        path: "notice",
        name: "notice",
        component: () => import("@/views/notice/NoticeList.vue"),
      },
      {
        path: "task",
        name: "task",
        component: () => import("@/views/task/TaskList.vue"),
      },
      {
        path: "theme",
        name: "theme",
        component: () => import("@/views/preferens/Theme.vue"),
      },
      {
        path: "themeInfo",
        name: "themeInfo",
        component: () => import("@/views/preferens/ThemeInfo.vue"),
      },

      {
        path: "account",
        name: "account",
        component: () => import("@/views/account/Account.vue"),
      },
      {
        path: "user",
        name: "user",
        component: () => import("@/views/user/List.vue"),
      },
      {
        path: "attribute",
        name: "attribute",
        component: () => import("@/views/user/Attribute.vue"),
      },
    ],
  },
  {
    path: "/userDetail",
    name: "ususerDetailer",
    component: () => import("@/views/user/UserDetail.vue"),
  },
  {
    path: "/productInfo",
    name: "productInfo",
    component: () => import("@/views/preferens/ProductInfo.vue"),
  },
  {
    path: "/flow",
    name: "flow",
    component: () => import("../views/flows/Flow.vue"),
    children: [],
  },
  {
    path: "/editFlow",
    name: "editFlow",
    component: () => import("../views/flows/Edit.vue"),
    children: [],
  },
  {
    path: "/advanceSetting",
    name: "advanceSetting",
    component: () => import("@/views/flows/Advance.vue"),
  },
  {
    path: "/flowDetails",
    name: "flowDetails",
    component: () => import("../views/flows/FlowDetailsLayout.vue"),
    children: [
      {
        path: "flowData",
        name: "flowData",
        component: () => import("../views/flows/FlowData.vue"),
        children: [],
      },
      {
        path: "/flowVersion",
        name: "flowVersion",
        component: () => import("../views/flows/Version.vue"),
        children: [],
      },

    ], 
  },
  {
    path: "/newTheme",
    name: "newTheme",
    component: () => import("@/views/preferens/NewTheme.vue"),
  },
  {
    path: "/flowRules",
    name: "flowRules",
    component: () => import("../views/flows/FlowRules.vue"),
    children: [],
  },
  // 插件卸载页面
  {
    path: "/ExtensionResearch",
    name: "ExtensionResearch",
    component: () => import("../views/research/ExtensionResearch.vue"),
  },
  {
    path: "/CodeInstall",
    name: "CodeInstall",
    component: () => import("../views/research/CodeInstall.vue"),
  },
  {
    path: "/research62fb88f164ca8b40ea2590dc",
    name: "research",
    component: () => import("../views/management/research.vue"),
  },
  // {
  //   path: "/6284b8927bac77a89bb8191cGetTenant",
  //   name: "tenant",
  //   component: () => import("../views/management/tenant.vue"),
  // },
  {
    path: "/admin6284b8927bac77a89bb8191",
    name: "tenant",
    component: () => import("../views/management/tenant.vue"),
  },
  {
    path: "/NavControl6284b8927bac77a89bb8191",
    name: "navControl",
    component: () => import("../views/management/NavControl.vue"),
  },

  {
    path: "/enviroment",
    name: "enviroment",
    component: () => import("../views/environment/Environment.vue"),
    
  },


];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
