
const float = {
  namespaced: true,
  state: {
    handleType: '', //breadcrumb 面包屑 || tabs 切换标签
    elementType: '', // components 配置内容页面 || text 配置内容页面 ||  skip 导航 内容页面 ||  contact 联系方式内容页面 ||  form 表单收集内容页面 || function 功能页面 || style 品牌化页面 || config 功能页面 
    elementIndex: 0, 
    functionType: '', // add 添加  || edit是编辑
  },
  mutations: {
    SET_RESET: (state) => {
          state.handleType = ''
          state.elementType = ''
          state.elementIndex = 0
          state.functionType = ''
    },
    SET_HANDLETYPE: (state, value) => {
          state.handleType = value
    },
    SET_ELEMENTTYPE: (state, value) => {
          state.elementType = value
    },
    SET_ELEMENTINDEX: (state, value) => {
          state.elementIndex = value
    },
    SET_FUNCTIONTYPE: (state, value) => {
          state.functionType = value
    },
    
  },
  actions: {
      stateResett({ commit }, value) {
          commit('SET_RESET', value)
      },
      stateSetHandleType({ commit }, value) {
          commit('SET_HANDLETYPE', value)
      },
      stateSetElementType({ commit }, value) {
          commit('SET_ELEMENTTYPE', value)
      },
      stateSetElementIndex({ commit }, value) {
          commit('SET_ELEMENTINDEX', value)
      },
      stateSetFunctionType({ commit }, value) {
          commit('SET_FUNCTIONTYPE', value)
      },
  },
}

export default float
