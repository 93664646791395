/**
 * 获取本、上、下周开始结束时间
 * @description 得到本、上、下周的起始、结束日期
 * @param {Number} n 不传或0代表本周，-1代表上周，1代表下周
 * @param {Number} type  0 开始时间  1 结束时间
 * @author Mt.flower
 */
export const getWeekTime = (n, type, separator = '-') => {
    let now = new Date()
    let day = now.getDay() //返回星期几的某一天;
    if (!type) {
      if (n == 1) {
        let dayNumber = day == 0 ? 0 : 7 - day
        now.setDate(now.getDate() + dayNumber + 1)
      } else if (n == -1) {
        let dayNumber = day == 0 ? 6: day - 1
        now.setDate(now.getDate() - dayNumber - 7)
      } else {
        let dayNumber = day == 0 ? 6: day - 1
        now.setDate(now.getDate() - dayNumber)
      }
    } else {
      if (n == 1) {
        let dayNumber = day == 0 ? 0 : 7 - day
        now.setDate(now.getDate() + dayNumber + 1 + 6) // 在周开始的日期上+6天=周结束
      } else if (n == -1) {
        let dayNumber = day == 0 ? 6: day - 1
        now.setDate(now.getDate() - dayNumber - 7 + 6)
      } else {
        let dayNumber = day == 0 ? 0: 7 - day
        now.setDate(now.getDate() + dayNumber)
      }
    }
    let date = now.getDate()
    let month = now.getMonth() + 1

    let s = now.getFullYear() + separator + (month < 10 ? '0' + month : month) + separator + (date < 10 ? '0' + date : date)
    return s

}


/**
 * 返回的是 年月日
 * @param {*} ts 
 * @param {*} splitStr 
 * @returns 
 */
export const dataTsFormat = (ts, splitStr='/') => {
  if(!ts){return '' }
  const date = new Date(ts);
    const y = date.getFullYear();
    const m = date.getMonth() < 9
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;

    const d = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    return `${y}${splitStr}${m}${splitStr}${d}`;
}

/**
 * 
 * 返回的是 年-月-日 时分秒
 * @param {*} ts 
 * @param {*} splitStr 
 * @param {*} type 
 * @returns 
 */
export const formatDateTime = (ts, splitStr='-', type = 'all') => {
    if(!ts){ ts = new Date()}

    const date = new Date(ts);
    const y = date.getFullYear();
    const m = date.getMonth() < 9
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    const d = date.getDate() < 10 
        ? `0${date.getDate()}` 
        : `${date.getDate()}`;

    const H = date.getHours() < 10 
        ? `0${date.getHours()}` 
        : `${date.getHours()}`;

    const M = date.getMinutes() < 10 
        ? `0${date.getMinutes()}` 
        : `${date.getMinutes()}`;

    const S = date.getSeconds() < 10 
        ? `0${date.getSeconds()}` 
        : `${date.getSeconds()}`;

    if(type === 'all'){
      return `${y}${splitStr}${m}${splitStr}${d} ${H}:${M}:${S}`;
    }else{
      return `${y}${splitStr}${m}${splitStr}${d}`;
    }
}

export const getTimeDay = (type = 'start') => {
  if(type === 'start'){
    return new Date(new Date().toLocaleDateString()).getTime();
  }else{
    return (new Date(new Date().toLocaleDateString()).getTime() +24 * 60 * 60 * 1000 -1);
  }
}
export const onGetPageHours = (time) => {
  return new Date("2000-01-01 " + time).getHours() 
}
export const onGetPageMinutes = (time) => {
  return new Date("2000-01-01 " + time).getMinutes() 
}

export const onChineseHoursMinutes = (time) => {
  let isPM = time.includes("PM");
  time = time.replace("AM","")	
  time = time.replace("PM","")	

  let chinese = '上午 '
  if(isPM ){
    chinese = '下午 '
  }

  return chinese+time
}

export const onGetWeek = (ts,type = 'chinese') => {
  const date = new Date(ts);
  var week;     
  if(date.getDay() === 0){
    if(type === 'chinese'){
      week = "日"   
    }else{
      week = 0  
    }

  }  
  if(date.getDay() === 1) {
    if(type === 'chinese'){
      week = "一" 
    }else{
      week = 1
    }
  
  }  
  if(date.getDay() === 2){
    if(type === 'chinese'){
      week = "二"   
    }else{
      week = 2
    }

  }   
  if(date.getDay() === 3){
    if(type === 'chinese'){
      week = "三"    
    }else{
      week = 3
    }
 
  } 
  if(date.getDay() === 4){
    if(type === 'chinese'){
      week = "四"    
    }else{
      week = 4
    }

  } 
  if(date.getDay() === 5){
    if(type === 'chinese'){
      week = "五" 
    }else{
      week = 5  
    }
  }     
  if(date.getDay() === 6){
    if(type === 'chinese'){
      week = "六"  
    }else{
      week = 6
    }
  }    
  return week;
} 
