import axios from "axios";
// eslint-disable-next-line
import router from "@/router/index";
// import qs from "qs";
import { ElMessage } from "element-plus";

// let baseUrl = process.env.URL; 
let baseUrl = "/aha2b";

axios.defaults.baseURL = baseUrl;
// axios.defaults.timeout = 5000;
axios.defaults.timeout = 20000;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem("token");
    req.headers["X-ACCESS-TOKEN"] = token;
    req.headers["Content-Type"] = "application/json";
    // req.headers["Access-Control-Allow-Origin"] = "http://localhost:8080";
    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const status =
      error.response && error.response.status ? error.response.status : 401;
    if (status === 401) {
      ElMessage({
        message: '登录信息过期，请重新登录',
        type: 'error',
      })
      router.push({ path: "/login", query: { type: "unauthorized" } });
      return;
    }
    Promise.reject(error);
  }
);

export function get(url, data) {
  return axios.get(url, data);
}

export function post(url, data) {
  return axios.post(url, data);
}

export function put(url, data) {
  return axios.put(url, data);
}

export function del(url, data) {
  return axios.delete(url, data);
}

export function uploader(url, file) {
  let params = new FormData();
  params.append("file", file);
  return axios.post(url, params);
}
