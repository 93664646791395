const sessionCache = {
    set (key, value) {
      if (!sessionStorage) {
        return
      }
      if (key != null && value != null) {
        sessionStorage.setItem(key, value)
      }
    },
    get (key) {
      if (!sessionStorage) {
        return null
      }
      if (key == null) {
        return null
      }
      return sessionStorage.getItem(key)
    },
    setAloneJSON (key, aloneKey, value) {
      let jsonValue = this.getJSON(key)
      jsonValue[aloneKey] = value
      this.setJSON(key, jsonValue)
    },
    setJSON (key, jsonValue) {
      if (jsonValue != null) {
        this.set(key, JSON.stringify(jsonValue))
      }
    },
    getJSON (key) {
      const value = this.get(key)
      if (value != null) {
        return JSON.parse(value)
      }else{
        return {}
      }
    },
    remove (key) {
      sessionStorage.removeItem(key);
    }
  }
  const localCache = {
    set (key, value) {
      if (!localStorage) {
        return
      }
      if (key != null && value != null) {
        localStorage.setItem(key, value)
      }
    },
    get (key) {
      if (!localStorage) {
        return null
      }
      if (key == null) {
        return null
      }
      return localStorage.getItem(key)
    },
    setAloneJSON (key, aloneKey, value) {
      let jsonValue = this.getJSON(key)
      jsonValue[aloneKey] = value
      this.setJSON(key, jsonValue)
    },
    setJSON (key, jsonValue) {
      if (jsonValue != null) {
        this.set(key, JSON.stringify(jsonValue))
      }
    },
    getAloneJSON (key, aloneKey) {
      let jsonValue = this.getJSON(key)
      if(jsonValue[aloneKey]){
        return jsonValue[aloneKey]
      }else{
        return null
      }
      
    },
    getJSON (key) {
      const value = this.get(key)
      if (value != null) {
        return JSON.parse(value)
      }else{
        return {}
      }
    },
    remove (key) {
      localStorage.removeItem(key);
    }
  }
  
export const cache = {
    /**
     * 会话级缓存
     */
    session: sessionCache,
    /**
     * 本地缓存
     */
    local: localCache
  }
  