import usertour from "usertour.js";
import { getCookie } from "@/tools/cookie";
/**
 *
 * @param {*} targetUrl
 */
export const handleHttpUrl = (targetUrl) => {
  if (
    targetUrl &&
    targetUrl.substr(0, 7).toLowerCase() !== "http://" &&
    targetUrl.substr(0, 8).toLowerCase() !== "https://"
  ) {
    targetUrl = "http://" + targetUrl;
  }

  return targetUrl;
};

export function getDomainName(url) {
  if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
    let domain = url.split("/");
    if (domain[2]) {
      domain = domain[2];
    } else {
      domain = "";
    }

    return domain;
  } else {
    return url;
  }
}

export function getPathName(url) {
  if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
    let arrUrl = url.split("//");
    let start = arrUrl[1].indexOf("/");
    let relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符
    if (relUrl.indexOf("?") != -1) {
      relUrl = relUrl.split("?")[0];
    }
    return relUrl;
  } else {
    return "";
  }
}

export function isUrl(url) {
  var str = url;
  // eslint-disable-next-line
  var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
  var objExp = new RegExp(Expression);
  if (objExp.test(str) == true) {
    return true;
  } else {
    return false;
  }
}

/**
 * 取出中括号内的内容
 * @param text
 * @returns {string}
 */
export function getBracketStr(text) {
  if (text.indexOf("[") != -1 && text.indexOf("]") != -1) {
    let a1 = /\[(.+?)\]/g;
    let a2 = text.match(a1).toString();
    let a3 = a2.replace("[", "");
    a3 = a3.replace("]", "");
    return a3;
  } else {
    return false;
  }
}

/**
 *
 * 判断当前域名是不是测试环境和开发环境
 * @returns
 *
 */
export function isTestEnvironment() {
  let location = window.location.href;

  if (
    location.search("http://localhost") !== -1 ||
    location.search("https://t.ahmoment.net") !== -1
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * 初始化 UserViewSDK
 */
// export function initUserViewSDK(){

//   function loadScript(url, apikey) {
//     var script =
//       document.createElement("script"); script.type = "text/javascript";
//     script.src = url; script.setAttribute("uploadmethod", "grpc");
//     script.setAttribute("batchmax", "20"); script.setAttribute("sendovertime",
//       "20000"); script.setAttribute("storesize", "100");
//     script.setAttribute("id", "eaRecordScript");
//     script.setAttribute("projectKey", apikey);
//     document.head.appendChild(script);
//   }

//   !isTestEnvironment() && loadScript('https://edge.userview.net/uv/1.2.3/record.js', '2f02f324f6c34af2906a24a35a1254ab')
//   return true
// }

/**
 *
 * @param {*} pageParam
 */
export function initAhamomentUserView(pageParam) {
  let defaultParamt = {
    uid: "",
    username: "",
    product: "",
  };
  Object.assign(defaultParamt, pageParam);

  let { uid, username, product } = defaultParamt;

  !uid && (uid = getCookie("B_USER_ID"));
  if (!uid) {
    return;
  }

  let userViewInfo = {
    uid,
    username,
  };
  // 收集 UserView SDK 相关数据
  window.recordInfoCase && window.recordInfoCase.identify(userViewInfo);

  let ahamomentInfo = {};
  if (product) {
    ahamomentInfo.product = product;
  }

  // 收集 ahamoment SDK 相关数据
  usertour.identify(uid, ahamomentInfo);

  return true;
}

export function isEmptyObj(obj) {
  if (JSON.stringify(obj) === "{}" || !obj) {
    return true;
  }
  return false;
}
export function getLocationPath(link) {
  let href = "";
  if (link) {
    let linkObj = new URL(handleHttpUrl(link));
    href = linkObj.href;
    let origin = linkObj.origin;
    href = href.replace(origin, "");
  }
  return href;
}

export function getRulesLocationPath(link) {
  let href = link;
  if (link.indexOf("http") === 0) {
    let linkObj = new URL(handleHttpUrl(link));

    href = linkObj.href;
    let origin = linkObj.origin;
    href = href.replace(origin, "");
  }
  return href;
}

export function getUrlPathAndQuery(url) {
  // url = handleHttpUrl(url)

  if (!url) {
    url = "";
  }
  if (url.indexOf("http") !== 0) {
    if (url.indexOf("/") === 0) {
      return url.replace(/\?.*/, "*");
    } else {
      return "/" + url.replace(/\?.*/, "*");
    }
  } else {
    if (url.indexOf("http://") === 0) {
      const a = url.replace(/^http:\/\/[^/]+/, "").replace(/\?.*/, "*");
      return a;
    }
    if (url.indexOf("https://") === 0) {
      const a = url.replace(/^https:\/\/[^/]+/, "").replace(/\?.*/, "*");

      return a;
    }
  }
}
export function getFilterProtocolUrl(url) {
  let data = {
    isHttps: false,
    url,
  };

  // console.log(url,'urlurlurl1111')

  if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
    if (url.indexOf("http://") === 0) {
      data.isHttps = false;
    }
    if (url.indexOf("https://") === 0) {
      data.isHttps = true;
    }
    // eslint-disable-next-line
    url = url.replace(/^https?\:\/\//i, "");
    // eslint-disable-next-line
    url = url.replace(/^http?\:\/\//i, "");
    data.url = url;
  }

  return data;
}

export function noRepeatArray(arr) {
  let newArr = JSON.parse(JSON.stringify(arr));
  let obj = {};
  return newArr.filter((item) => {
    // 防止key重复
    let newItem = item + JSON.stringify(item);
    // eslint-disable-next-line
    return obj.hasOwnProperty(newItem) ? false : (obj[newItem] = true);
  });

  // return newArr;
}

export function in_array(value, array) {
  return array.includes(value);
}

export function onGetMsecToSecond(msec) {
  return Math.ceil(msec / 1000);
}

export function onFindValueToKey(field, arr) {
  let tmpObj = {};
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];

    if (element[field]) {
      tmpObj[element[field]] = element;
    }
  }

  return tmpObj;
}
export async function getImgWidthHeight(src, maxWaitLoad = 2500) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = src;
    if (img.complete) {
      const { width, height } = img;
      resolve({
        width,
        height,
      });
    } else {
      let timeOut = setTimeout(() => {
        reject("图片加载失败！");
      }, maxWaitLoad);
      img.onload = function () {
        const { width, height } = img;
        window.clearTimeout(timeOut);
        resolve({
          width,
          height,
        });
      };
    }
  });
}
