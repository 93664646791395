// eslint-disable-next-line
import { ref, reactive, toRefs, onMounted, watch } from "vue";
import { useRoute } from 'vue-router';
import { getTimeDay } from "@/tools/date";
import { useExtensionEffect, onGetExtensionVersion, installExtension } from "@/tools/extension";
import { STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_NO_ALERT_CRX_VERSION, STORAGE_CURRENT_VERSION_ALERT_DAY } from "@/tools/storageDict";
import { cache } from "@/tools/cache";
import { isTestEnvironment } from "@/tools/utils";
export default {
  name: "GlobalExtensionVersion",
  props: {
    param: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  components: {},
  // eslint-disable-next-line
  setup(props, {
    emit
  }) {
    const route = useRoute();
    watch(() => route.name, n => {
      routeName.value = n;
      onVerifyIsShow();
    });
    const {
      crxVersion,
      hasExtension,
      versionData,
      noAlert,
      isShow,
      routeName,
      detailLength,
      forced,
      onInitData,
      onInstallExtension,
      onISee,
      onVerifyIsShow
    } = usePageEffect();
    const {
      isShowMore,
      setIsShowMove
    } = useTheTextIsOmittedEffect(); //展开部分

    onMounted(() => {
      onInitData();
    });
    return {
      crxVersion,
      hasExtension,
      versionData,
      isShowMore,
      isShow,
      noAlert,
      detailLength,
      forced,
      onInstallExtension,
      setIsShowMove,
      onISee
    };
  }
};

// 处理页面数据交互的所有逻辑
const usePageEffect = () => {
  const {
    crxVersion,
    hasExtension,
    onInitExtensionLogic,
    onPingToCrxVersion
  } = useExtensionEffect(); //插件部分

  const data = reactive({
    versionData: {},
    latestStatus: false,
    detailLength: 0,
    crxVersion,
    hasExtension,
    noAlert: false,
    isShow: false,
    forced: false,
    routeName: ''
  });
  const onInitData = async () => {
    onInitExtensionLogic();
    onInitCrxVersion();
    onInitGetExtensionVersion();
    onVerifyIsShow();
  };
  const onISee = async () => {
    data.noAlert && cache.local.setAloneJSON(STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_NO_ALERT_CRX_VERSION, data.versionData.currentOnLineVersion);
    if (isTestEnvironment()) {
      cache.local.setAloneJSON(STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_VERSION_ALERT_DAY, new Date().valueOf() + 6000000); //测试时间
    } else {
      cache.local.setAloneJSON(STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_VERSION_ALERT_DAY, getTimeDay('end')); //正式时间
    }

    isShow.value = false;
  };
  const onVerifyIsShow = () => {
    // let currentCrxVersion = cache.local.getAloneJSON(STORAGE_CURRENT_CRX_INFO,STORAGE_CURRENT_CRX_VERSION)//本地插件版本 

    let versionAlertDay = cache.local.getAloneJSON(STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_VERSION_ALERT_DAY);
    let currentNoAlertCrxVersion = cache.local.getAloneJSON(STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_NO_ALERT_CRX_VERSION);
    if (data.routeName !== "home" && data.routeName !== "ExtensionResearch" && data.routeName !== "login") {
      data.isShow = true;

      // // console.log(!currentCrxVersion,'currentCrxVersioncurrentCrxVersion')
      // if(!currentCrxVersion){
      //   data.isShow = true;
      // }
    } else {
      data.isShow = false;
    }
    const currentTime = new Date().valueOf();

    //  console.log(( currentNoAlertCrxVersion && data.versionData.currentOnLineVersion === currentNoAlertCrxVersion),'( currentNoAlertCrxVersion && data.versionData.currentOnLineVersion === currentNoAlertCrxVersion)')

    //  console.log((versionAlertDay && versionAlertDay > currentTime),'(versionAlertDay && versionAlertDay > currentTime)')

    if (currentNoAlertCrxVersion && data.versionData.currentOnLineVersion === currentNoAlertCrxVersion) {
      data.isShow = false;
    }
    if (versionAlertDay && versionAlertDay > currentTime) {
      data.isShow = false;
    }

    // if( data.crxVersion &&  data.crxVersion !==  data.versionData.currentOnLineVersion){
    //    data.isShow = true;
    // }

    // console.log((data.isShow),'(data.isShowdata.isShow)')
  };

  const onInitGetExtensionVersion = async () => {
    let versionAlertDay = cache.local.getAloneJSON(STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_VERSION_ALERT_DAY);
    let currentNoAlertCrxVersion = cache.local.getAloneJSON(STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_NO_ALERT_CRX_VERSION);
    const currentTime = new Date().valueOf();
    // 防止重复请求参数 时间过期的 或者 没有记录 我知道了结束时间 和本版本不在显示的 才进行请求
    if (!versionAlertDay || versionAlertDay && versionAlertDay < currentTime || !currentNoAlertCrxVersion) {
      data.versionData = await onGetExtensionVersion();
      data.versionData.currentOnLineDetail && (data.detailLength = data.versionData.currentOnLineDetail.length);
      data.versionData.currentCrxforcedType && data.versionData.currentCrxforcedType.length > 0 && (data.forced = true);
    }
  };
  const onInitCrxVersion = () => {
    setTimeout(() => {
      onPingToCrxVersion();
    }, 1000);
  };
  const onInstallExtension = () => {
    installExtension();
  };
  const {
    versionData,
    noAlert,
    isShow,
    routeName,
    detailLength,
    forced
  } = toRefs(data);
  return {
    crxVersion,
    hasExtension,
    versionData,
    noAlert,
    isShow,
    routeName,
    detailLength,
    forced,
    onInitData,
    onInitCrxVersion,
    onInstallExtension,
    onISee,
    onVerifyIsShow
  };
};
const useTheTextIsOmittedEffect = () => {
  const data = reactive({
    isShowMore: false
  });
  const setIsShowMove = () => {
    data.isShowMore = !data.isShowMore;
  };
  const {
    isShowMore
  } = toRefs(data);
  return {
    isShowMore,
    setIsShowMove
  };
};