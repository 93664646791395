import { handleHttpUrl } from "@/tools/utils";
import { STORAGE_CURRENT_CRX_INFO ,  STORAGE_CURRENT_CRX_VERSION, STORAGE_CURRENT_ONLINE_VERSION,STORAGE_CURRENT_CRX_FORCED_TYPE,STORAGE_CURRENT_ONLINE_DETAIL, STORAGE_CURRENT_ONLINE_UPDATE_TIME } from "@/tools/storageDict";
import { cache } from "@/tools/cache";
import { noRepeatArray } from "@/tools/utils";
// eslint-disable-next-line
import { post, get, put } from "@/api/http";
import { reactive, toRefs } from "vue";
import { ElMessage } from "element-plus";
export const onPingToExtension = (requestType, pageParam = {}) => {
    return new Promise((resolve, reject)=>{   
        let defaultParamt = {
            callback: ()=>{}, 
            targetUrl: "", 
            hasExtension: "", 
            mode: "", 
            flowId: "", 
            editIndex: "", 
            checklistId: "", 
            noticeId: "", 
            npsId: "", 
            floatId: "", 
            launcherId: "", 
            isPreview: true, 
            originData: {}, 
            previewTypes: [], 
            envId: "", 
            themeId: "", 
        };
        Object.assign(defaultParamt,pageParam);
    
        let { targetUrl, mode, hasExtension, flowId, editIndex, isPreview, previewTypes, checklistId, npsId, floatId, noticeId, launcherId, originData, themeId } = defaultParamt
        targetUrl = handleHttpUrl(targetUrl)
        let token = localStorage.getItem("token");
        const envId = localStorage.getItem("currentEnvId");
       
        setTimeout(() => {
            if (!hasExtension && requestType !== 'aha:crxHasExtension') {
                reject({
                    hasExtension:false
                })
                return;
            }
            switch (requestType) {
            case "aha:crxLoad": { 
                // 添加逻辑
                window.postMessage(
                    {
                    requestType,
                    param: {
                        token,
                        envId,
                        flowId,
                        targetUrl,
                    },
                    },
                    window.location.href
                );
                return;
            }
            case "aha:crxEdit":
                // 编辑流
                window.postMessage(
                  {
                    requestType,
                    param: {
                      token,
                      flowId,
                      consoleStorageType:'edit',
                      envId,
                      editIndex,
                      targetUrl,
                    },
                  },
                  window.location.href
                );
                window.open(targetUrl, "_blank");
            return;
    
            case "aha:crxHasExtension": {
                // 验证是否安装了插件
                window.postMessage(
                    {
                        requestType: "aha:crxHasExtension",
                        param: {
                          verifyHasExtension: true,
                        },
                    },
                    window.location.href
                );
                resolve();
                return;
            }
            case "aha:crxGetExtensionVersion": {
                // 获取插件版本
                window.postMessage(
                    {
                        requestType: "aha:crxGetExtensionVersion",
                        param: {
                          getVersion: true,
                        },
                    },
                    window.location.href
                );
                resolve();
                return;
            }
            case "aha:crxJumpExtension": {
                // 插件开启页面逻辑
                window.postMessage(
                    {
                      requestType: "aha:crxJumpExtension",
                      param: {
                        targetUrl,
                      },
                    },
                    window.location.href
                  );
                resolve();
                return;
            }
            case "aha:crxAbandon": {
                // 放弃插件逻辑
                window.postMessage(
                  {
                    requestType,
                    param: {
                      token,
                    },
                  },
                  window.location.href
                );
                return;
            }    
            case "aha:crxContinue": {
                // 继续插件逻辑
                window.postMessage(
                  {
                    requestType,
                    param: {
                      token,
                    },
                  },
                  window.location.href
                );
                return;
            }    

            case "aha:crxTargetPageElemennt": {
                // 清单逻辑
                window.postMessage(
                    {
                        requestType,
                        param: {
                            token,
                            mode,
                            targetUrl,
                        },
                    },
                    window.location.href
                );
                window.open(targetUrl, "_blank");
                resolve();
                return;
            }
            case "aha:crxLauncher": {
                // 启动器逻辑
                window.postMessage(
                    {
                        requestType,
                        param: {
                            token,
                            mode,
                            targetUrl,
                            originData,
                        },
                    },
                    window.location.href
                );
                window.open(targetUrl, "_blank");
                resolve();
                return;
            }
            case "aha:crxNotice": {
                // 启动器逻辑
                window.postMessage(
                    {
                        requestType,
                        param: {
                            token,
                            mode,
                            targetUrl,
                            originData,
                        },
                    },
                    window.location.href
                );
                window.open(targetUrl, "_blank");
                resolve();
                return;
            }
            case "aha:crxVerifySdk": {
                // 启动器逻辑
                window.postMessage(
                    {
                        requestType,
                        param: {
                            token,
                            envId,
                            mode,
                            targetUrl,
                            originData,
                        },
                    },
                    window.location.href
                );
                window.open(targetUrl, "_blank");
                resolve();
                return;
            }
            case "aha:crxTheme": {
                // 皮肤逻辑
                window.postMessage(
                    {
                        requestType,
                        param: {
                            token,
                            mode,
                            targetUrl,
                            themeId,
                        },
                    },
                    window.location.href
                );
                window.open(targetUrl, "_blank");
                resolve();
                return;
            }
            case "aha:crxPreview": {
                // 预览逻辑
                window.postMessage(
                  {
                    requestType,
                    param: {
                      token,
                      envId,
                      flowId,
                      checklistId,
                      noticeId,
                      npsId,
                      floatId,
                      launcherId,
                      previewTypes:previewTypes.toString(),
                      targetUrl,
                      isPreview,
                      mode,
                      originData,
                    },
                  },
                  window.location.href
                );
    
                window.open(targetUrl, "_blank");
                resolve();
                return;
              }
    
            default:
                resolve();
                return;
            }
        }, 50);

        
     })


}

export const onExtensionListener = (e, pageParam = {}) => {
    let defaultParamt = {
        callback: ()=>{}, 
    };

    Object.assign(defaultParamt,pageParam);
    const { data } = e;
    if (!data.param) {return; }
    switch (data.requestType) {
        case "aha:crxCompleteTab": {
            if (data.param.complete) {
                let param = data.param.param
                param['callbackType'] = 'crxCompleteTab'
                param['complete'] = data.param.complete
                param && JSON.stringify(param) !=="{}" && defaultParamt.callback(param)
            }
            return;
        }
        case "aha:crxHasExtensionBack": {
            if (data.param.hasExtension) {
                let param = data.param
                param['callbackType'] = 'crxHasExtensionBack'
                param && JSON.stringify(param) !=="{}" && defaultParamt.callback(param)
            }
            return;
        }
        case "aha:crxGetExtensionVersionBack": {
            if (data.param.crxVersion) {
                let param = data.param
                param['callbackType'] = 'crxGetExtensionVersionBack'
                param && JSON.stringify(param) !=="{}" && defaultParamt.callback(param)
            }
            return;
        }        

        default:
        return;
    }
}

// 公共的处理页面中插件的基础交互功能  后期增加回调方法
export const useExtensionEffect = (pageParam) => {
    let defaultParamt = {
      onPageToCrxCallback: ()=>{}, 
    };

    Object.assign(defaultParamt,pageParam);
    const data = reactive({ 
      hasExtension: false, //插件是否存在变量
      crxVersion: 0, //本地插件版本  
      extensionData:{ }
    })
  
    // 初始化插件逻辑
    const onInitExtensionLogic = () => {
       onPingToExtension("aha:crxHasExtension");
       window.addEventListener('message',onInitExtensionLogicFunction,true)
    }

    // 插件逻辑方法
    const onInitExtensionLogicFunction = (e) => {
       onExtensionListener(e,{callback:onExtensionCallback})
    }
    const onRemoveExtensionLogic = () => {
       window.removeEventListener('message', onInitExtensionLogicFunction,true);  
    }
  
    // 插件回调的方法
    const onExtensionCallback = (param) => {
        if(param.callbackType === 'crxHasExtensionBack'){
            data.hasExtension = param.hasExtension;
        }
        if(param.callbackType === 'crxGetExtensionVersionBack'){
            data.crxVersion = param.crxVersion;
            cache.local.setAloneJSON(STORAGE_CURRENT_CRX_INFO,STORAGE_CURRENT_CRX_VERSION,param.crxVersion)
        }
        // 下面为页面不同页面的逻辑回调
        defaultParamt.onPageToCrxCallback(param)
    };
  
    // 验证插件是否存在
    const onPingToCrxHasExtension = ()=>{
        let { hasExtension } = data
        return hasExtension
    }
    // 通用的预览
    const onPingVerifySdk = (pageParam)=>{
      return new Promise((resolve, reject)=>{
       let defaultParamt = {
            noExtensionCallback: ()=>{}, 
            targetUrl: "", 
            mode: "", 
            envId: "", 
            originData:{}, 
        };

       Object.assign(defaultParamt,pageParam);

       let { targetUrl, mode, envId, originData  } = defaultParamt

       let {hasExtension} = data
       onPingToExtension("aha:crxVerifySdk", {
              hasExtension,
              targetUrl,
              envId,
              originData,
              mode,
          }).catch(() => {});
          if(!hasExtension){
            reject({hasExtension })
            return;
          }

        resolve();
        return;
      })  


    }
    // 通用的预览
    const onPingToCrxPreview = (pageParam)=>{
      return new Promise((resolve, reject)=>{

      if (onGetNotLatestStatus()) {
          reject({notLatestStatus:true })
          return;
      }
       let defaultParamt = {
            noExtensionCallback: ()=>{}, 
            mode: "", 
            envId: "", 
            checklistId: "", 
            noticeId: "", 
            npsId: "", 
            floatId: "", 
            launcherId: "", 
            targetUrl: "", 
            isPreview: true, 
            previewTypes: [], 
            flowId: "", 
            originData: {}, 
        };

       Object.assign(defaultParamt,pageParam);

       let { targetUrl, mode, envId, flowId, isPreview, checklistId, npsId, floatId, noticeId, launcherId, previewTypes, originData } = defaultParamt

       let {hasExtension} = data
       onPingToExtension("aha:crxPreview", {
            hasExtension,
            targetUrl,
            flowId,
            envId,
            checklistId,
            npsId,
            floatId,
            noticeId,
            launcherId,
            previewTypes,
            mode,
            isPreview,
            originData,
        }).catch((res) => {
          let {hasExtension} = res
          defaultParamt.noExtensionCallback(
                hasExtension
          )
        });   
          resolve();
          return;
      })  
    }
    // 验证当前插件是不是最新插件
    const onVerifyCrxIsNew = ()=>{

      return new Promise((resolve, reject)=>{

        if (onGetNotLatestStatus()) {
            reject({notLatestStatus:true })
            return;
        }
 
          resolve();
          return;
      })  
    }
    // 获取版本信息
    const onPingToCrxVersion = (pageParam)=>{
       let defaultParamt = {
            noExtensionCallback: ()=>{}, 
        };

       Object.assign(defaultParamt,pageParam);
       let {hasExtension} = data

       onPingToExtension("aha:crxGetExtensionVersion", {
            hasExtension,
        }).catch((res) => {
          let {hasExtension} = res
          // console.log(res,'resresres')
          !hasExtension && cache.local.remove(STORAGE_CURRENT_CRX_INFO)
        });     
    }

    // 编辑流页面的逻辑
    const onEditFlowToCrxPing = async (pageParam)=>{
      // eslint-disable-next-line no-async-promise-executor
      return new Promise( async (resolve, reject)=>{ 
        let defaultParamt = {
                requestType: "", 
                flow: {}, 
                flowItem: {}, 
                editIndex: '', 
                previewUrl: {}, 
                isPreview: true, 
                previewTypes: [], 
                targetUrl: '', 
            };

        Object.assign(defaultParamt,pageParam);
    
        let { requestType, flow, editIndex, flowItem, targetUrl, previewUrl, previewTypes, isPreview } = defaultParamt
        flow.value && (targetUrl = flow.value.link)
        const envId = localStorage.getItem("currentEnvId");
        let {hasExtension} = data


       var notLatestRequestTypes = ['aha:crxLoad','aha:crxEdit','aha:crxPreview']

       if (notLatestRequestTypes.includes(requestType) && onGetNotLatestStatus()) {
            reject({notLatestStatus:true })
            return;
        }


        switch (requestType) {
              case "aha:crxLoad": {
                let tmpFlowSteps = JSON.parse(JSON.stringify(flow.value.steps));
                let lastFlowStep = tmpFlowSteps[tmpFlowSteps.length - 1]; // eslint-disable-line no-unused-vars

                if (lastFlowStep && lastFlowStep.crxTargetUrl) {
                  targetUrl = lastFlowStep.crxTargetUrl;
                }

                onPingToExtension(requestType, {
                    hasExtension,
                    flowId: flow.value._id,
                    targetUrl,
                }).catch((res) => {
                  let {hasExtension} = res
                  !hasExtension && ElMessage("未安装插件");
                });
              
                onCrxJumpExtension(targetUrl)
                resolve();
              return;
            }
            case "aha:crxEdit": {
                flowItem.crxTargetUrl && (targetUrl = flowItem.crxTargetUrl)
                onPingToExtension(requestType, {
                    hasExtension,
                    flowId: flow.value._id,
                    editIndex: `${editIndex}`,
                    targetUrl,
                }).catch((res) => {
                  let {hasExtension} = res
                  !hasExtension && ElMessage("未安装插件");
                });
                resolve();
                return;
            }
            case "aha:crxPreview": {
                let tmpSteps = JSON.parse(JSON.stringify(flow.value.steps));
                if (tmpSteps.length > 0) {
                  targetUrl = tmpSteps[0].crxTargetUrl;
                }
                previewTypes = 'preview';
                let originData = {
                  flowIds:'',
                }

                previewUrl && (targetUrl = previewUrl)
                originData.flowIds =  await onGetFlowRelatedFlowId(flow.value._id)

                onPingToExtension("aha:crxPreview", {
                    hasExtension,
                    targetUrl,
                    originData,
                    envId,
                    isPreview,
                    previewTypes,
                    mode: "preview",
                }).catch((res) => {
                  let {hasExtension} = res
                  !hasExtension && ElMessage("未安装插件");
                });   
                
                resolve();
                return;
            }
            case "aha:crxAbandon": {
                onPingToExtension(requestType, { hasExtension }).catch(() => { })
                resolve();
                return;
            }
            case "aha:crxContinue": {
                onPingToExtension(requestType, { hasExtension })
                resolve();
                return;
            }
        }
      })
    }

    // 通过插件开启url
    const onCrxJumpExtension = (targetUrl) => {
      let {hasExtension} = data
       onPingToExtension('aha:crxJumpExtension', {
               hasExtension,
               targetUrl,
       })
    };

    // 获取目标页面元素的逻辑
    const onTargetPageElemenntToCrxPing = (mode,targetUrl)=>{
      let {hasExtension} = data
      
      onPingToExtension("aha:crxTargetPageElemennt", {
          hasExtension,
          targetUrl,
          mode,
      }).catch((res) => {
        let {hasExtension} = res
        !hasExtension && ElMessage("未安装插件");
      });
  }
    // 启动器的逻辑
    const onLauncherEditToCrxPing = (mode,targetUrl,originData={})=>{
      return new Promise((resolve, reject)=>{  
          let {hasExtension} = data
            onPingToExtension("aha:crxLauncher", {
                hasExtension,
                targetUrl,
                originData,
                mode,
            }).catch(() => {});
            if(!hasExtension){
              reject({hasExtension })
              return;
            }

          resolve();
          return;
       })

  }
    // 公告的逻辑
    const onNoticeEditToCrxPing = (mode,targetUrl,originData={})=>{
      return new Promise((resolve, reject)=>{  
          let {hasExtension} = data
            onPingToExtension("aha:crxNotice", {
                hasExtension,
                targetUrl,
                originData,
                mode,
            }).catch(() => {});
            if(!hasExtension){
              reject({hasExtension })
              return;
            }

          resolve();
          return;
       })

  }
    // 主题的逻辑
    const onThemeEditToCrxPing = (mode,targetUrl,themeId)=>{
        let {hasExtension} = data
        onPingToExtension("aha:crxTheme", {
            hasExtension,
            targetUrl,
            themeId,
            mode,
        }).catch((res) => {
          let {hasExtension} = res
          !hasExtension && ElMessage("未安装插件");
        });
    }
  
    const { hasExtension, crxVersion, extensionData } = toRefs(data)
  
    return { 
      hasExtension,
      crxVersion,
      extensionData,
      onTargetPageElemenntToCrxPing,
      onLauncherEditToCrxPing,
      onNoticeEditToCrxPing,
      onInitExtensionLogic,
      onRemoveExtensionLogic,
      onPingToCrxVersion,
      onEditFlowToCrxPing,
      onPingToCrxPreview,
      onPingVerifySdk,
      onThemeEditToCrxPing,
      onPingToCrxHasExtension,
      onVerifyCrxIsNew,
     }
  }

export const installExtension = () => {
  let t = Date.now();
  window.open(
    `${process.env.VUE_APP_EXTENSION_URL}?t=${t}`,
    "_blank"
  );
}


/**
 * 
 * @param {*} type default 部分页面 || all  所有页面
 * @returns 
 */
export function onGetNotLatestStatus() {
  let crxVersion = cache.local.getAloneJSON(STORAGE_CURRENT_CRX_INFO,STORAGE_CURRENT_CRX_VERSION)//本地插件版本  
  let onLineVersion = cache.local.getAloneJSON(STORAGE_CURRENT_CRX_INFO,STORAGE_CURRENT_ONLINE_VERSION)
  let currentCrxforcedType = cache.local.getAloneJSON(STORAGE_CURRENT_CRX_INFO,STORAGE_CURRENT_CRX_FORCED_TYPE)

  if ((currentCrxforcedType && currentCrxforcedType.includes(getPageType()) ) && onLineVersion && crxVersion !== onLineVersion) {
    return true ;
  }else{
    return false
  }

}
/**
 * 根据页面url判断页面类型
 * @returns 
 */
export function getPageType() {
  
  let href = window.location.href

  if(  
    href.search("admin/task") !== -1 ||
    href.search("taskEdit") !== -1 
  
  ){
      return 'task'
  }

  if(  
    href.search("admin/chatbox") !== -1 ||
    href.search("editChatbox") !== -1 ||
    href.search("admin/float") !== -1 ||
    href.search("admin/floatLayout") !== -1
  
  ){
      return 'floating'
  }
  if(  
    href.search("flowVersion") !== -1 ||
    href.search("newTheme") !== -1 ||
    href.search("editFlow") !== -1 
  
  ){
      return 'flow'
  }

  if(  
    href.search("launcherEdit") !== -1 
  ){
      return 'launcher'
  }
}

export async function onGetExtensionVersion() {
  return new Promise(resolve=>{   
    // let onLineVersion = cache.local.getAloneJSON(STORAGE_CURRENT_CRX_INFO,STORAGE_CURRENT_ONLINE_VERSION)

      get(`/free/getAllPlugin`).then((res) => {
        if (res.data.success) {
          let versionData = res.data.data[0];
    
          cache.local.setAloneJSON(STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_ONLINE_VERSION, versionData.version)
          cache.local.setAloneJSON(STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_ONLINE_DETAIL, versionData.detail)
          cache.local.setAloneJSON(STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_ONLINE_UPDATE_TIME, versionData.updateTimeFormat)
          cache.local.setAloneJSON(STORAGE_CURRENT_CRX_INFO, STORAGE_CURRENT_CRX_FORCED_TYPE, versionData.forced)
          let versionInfo = cache.local.getJSON(STORAGE_CURRENT_CRX_INFO)
          resolve(versionInfo)
        }
      })
 
  })
}
// 根据id获取所有引导流关联的流id
export async function onGetFlowRelatedFlowId(ids) {
// eslint-disable-next-line no-async-promise-executor
  return new Promise( async (resolve) =>{   
    let idsArr = ids.split(",").filter(item=> item)
    let tmpIdsArr =  JSON.parse(JSON.stringify(idsArr)) 
    for (let index = 0; index < tmpIdsArr.length; index++) {
      const element = tmpIdsArr[index];
      await get(`/free/getFlowRelatedFlowId/${element}`).then((res) => {
            if (res.data.success) {
              let idsData = res.data.data.ids;
              idsData &&(idsArr = idsArr.concat(idsData))
            }
          })
    }
    resolve(noRepeatArray(idsArr).join())
  })
}

/**
 * 
 * @param {*} crxField 
 * @param {*} type  small || medium || large
 */ 
export function onGetScreenshotStyle(crxField, type = 'medium') {
      let tmpX = 120
      let tmpY = 70
      let scale = 0.85
      if(type === 'large'){ //待定
        tmpX = 200
        tmpY = 100
      }
      if(type === 'small'){ 
        tmpX = 136
        tmpY = 36
        scale = 0.6
      }

      // console.log(crxField,'crxFieldcrxField')

      let {screenshot, selectEle} = crxField
      // styles
      let translateX = '';
      let translateY = '';

      if(screenshot.mousePos.x - tmpX > 0){
          translateX = `translateX(-${(screenshot.mousePos.x - tmpX)}px)`
      }

      if(screenshot.mousePos.y - tmpY > 0){
          translateY = `translateY(-${(screenshot.mousePos.y - tmpY)}px)`
      }
      let {width,height} = screenshot.mousePos
      let {width:currentPopRefWidth} = selectEle

      if(height > 10000){
        height = 'auto'
      }else{
        height =  height+'px'
      }

      width = width+'px'

      let imgStyle = {
          width,
          height,
          transform:`scale(${scale}) ${translateX} ${translateY}`
      }

      if(parseInt(width) - currentPopRefWidth < 400 ){
          imgStyle.width = '100%'
          imgStyle.height = '100%'
          imgStyle.transform = 'scale(1) translateX(0px) translateY(0px) '
      }else if(currentPopRefWidth > 350){
          imgStyle.width = '100%'
          imgStyle.height = '100%'
          imgStyle.transform = 'scale(1) translateX(0px) translateY(0px) '
      }

      return imgStyle
    
}